.collapse-card-ans-top-div{
    font-size: 16px;
    font-weight: 500;
    padding-left: 15px;
    font-family: 'Gilroy-regular';
}
li{
    font-family: 'Gilroy-regular';
    font-size: 16px;
    font-weight: 500;
}

