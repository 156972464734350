.sliderWrapper {
  & .slick-list {
    padding-bottom: 1rem;
  }
  & .slick-dots {
    padding-top: 1rem;

    & .slick-active{
     &  button {
        &::before {
          color: #3393f9 !important;
          font-size: 12px;
        }
      }
    }
    & li {
      button {
        &::before {
          color: #c6cbd4 !important;
          font-size: 12px;
          opacity: 1;
        }
      }
    }
  }
}

slick-dots li.slick-active button:before {
  color: blue !important;
}

@media (max-width: 800px) {
  .sliderWrapperResponse {
    width: 86vw;
    margin: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px)  {
  .sliderWrapperResponse {
    width: 46vw !important;
  }
}