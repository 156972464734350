@mixin flex-base{
  display: flex;
  align-items: center;
}

.testimonial{
  border: 1px solid #eee;
  border-radius: 18px;
  width: 350px;
  padding: 10px;
  box-shadow: 0px 10px 30px rgba(94, 125, 182, 0.1);
  .testimonial-content-wrapper{
    height: 300px;
    p{
      font-family: 'Gilroy-regular';
      color: #091C3F;
      line-height: 1.57;
      padding: 10px;
      word-spacing: 4px;
    }
  }

  .testimonial-author-info-wrapper{
    height: 105px;
    @include flex-base;
    justify-content: space-between;
    .author-image{
      width: 30%;
      @include flex-base;
      justify-content: space-around;
    }

    .author-info{
      width: 70%;
      p{
        width: 100%;
        margin: 0;
      }

      .name{
        color: #091C3F;
      }

      .organization{
        color: #6B778C;
        font-family: 'Gilroy-regular';
      }

      .designation{
        font-size: 0.875rem;
        color: #091C3F;
        font-family: 'Gilroy-regular';
      }

      @include flex-base;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
}