.why-onsurity-card{
  width: fit-content;
  .why-onsurity-card-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin: 0;
    padding: 0;

    .card-img-wrapper{
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 70px;
      height: 70px;
      img{
        width: 50px;
      }
    }

    .card-text{
        font-family: 'Gilroy-regular';
        margin-top: 10px;
        font-size: 1.125rem;
    }
  }
}