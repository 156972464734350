// div{
//        border: 1px solid red;
// }

.HomeheaderCls .navbar {
  font-size: 16px;
  background-color: white;
  /* padding-top: 10px; */
  padding: 0px 0px 0px 0px;
  // background: linear-gradient(white, rgba(255, 255, 255, 0.3));
}

#login-link {
  border: none;
  outline: none;
  width: 140px;
  border-radius: 30px;
  padding: 10px;
  font-size: 13px;
  background: #fff;
  color: #1987fb;
  border: 1px solid #1987fb;
  cursor: pointer;
  justify-content: center;
}

.HomeheaderCls .main-nav {
  list-style-type: none;
  // display: none;
}

.main-navigation {
  list-style-type: none;
}

.HomeheaderCls .nav-links,
.HomeheaderCls .logo {
  text-decoration: none;
}

.HomeheaderCls .logo {
  display: inline-block;
  /* margin-left: 20px; */
}

.HomeheaderCls .onsuimg {
  height: 35px;
  margin-top: 10px;
  text-align: center;
}

#getapp {
  border: none;
  outline: none;
  color: #007cff;
  width: 140px;
  border-radius: 30px;
  padding: 10px;
  font-size: 13px;
  background: #ffffff;
  border: 1px solid rgba(9, 28, 63, 0.25);
  cursor: pointer;
}

#getapp:hover {
  border: 1px solid #007cff;
  color: #007cff;
  // transition: color 500ms;
  // transition: border 500ms;
}

.selectedNav {
  color: #007cff !important;
}
.myaccountflex {
  margin-left: 1rem !important;
}
#myaccount {
  // padding-left: 1rem;
  border: none;
  outline: none;
  width: 140px;
  border-radius: 30px;
  padding: 10px;
  font-size: 13px;
  color: white;
  background-color: #007dff;
  margin-top: 0px;
  cursor: pointer;
  justify-content: center;
}

#myaccount:hover {
  background-color: #1b6fc9;
}
#navv {
  font-size: 17px;
  color: #091c3f;
  line-height: 19px;
}

.HomeheaderCls .header {
  /* border: 1px solid green; */
  width: 100%;
  margin: auto;
  margin-top: 10px;
}

.HomeheaderCls .getappflex {
  padding-top: 0px;
}

.HomeheaderCls .navbar-toggle {
  position: absolute;
  // top: 15px;
  right: 14px;
  cursor: pointer;
  color: black;
  font-size: 25px;
}

.HomeheaderCls .active {
  display: block;
  padding: 0px;
  margin-top: 60px;
}

.HomeheaderCls .headMob {
  display: none;
  height: 70px;
}

#navv:hover {
  color: #1987fb;
}


.HomeheaderCls .headWeb {
  display: block;
  height: 70px;
}

.acount-details-div {
  position: absolute;
  width: 240px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(94, 125, 182, 0.1);
  border-radius: 5px;
  margin-top: 60px;
}
.acount-details-div-mob {
  position: absolute;
  right: 0;
  width: 240px;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(94, 125, 182, 0.1);
  border-radius: 5px;
  margin-top: 83px;
}

.acchol-name {
  font-family: Gilroy;
  font-weight: 800;
  font-size: 18px;
  color: #091c3f;
}

.achol-numnber {
  font-family: Roboto;
  font-size: 14px;
  color: #6b778c;
  margin-top: 5px;
}

.wrp-account-sec {
  margin-left: 25px;
  margin-top: 15px;
}

.account-sec-hr {
  width: 90%;
  border-top: 0.3px solid rgba(123, 130, 146, 0.4);
  margin-top: 15px;
}

.dashboard-emp {
  font-family: Roboto;
  font-size: 14px;
  color: #1987fb;
  margin-top: 10px;
  cursor: pointer;
}

.logout-sec {
  font-family: Roboto;
  font-size: 14px;
  color: #091c3f;
  margin-top: 10px;
  cursor: pointer;
}

.HomeheaderCls .liLink {
  margin-bottom: auto !important ;
  font-family: Gilroy-regular;
}

//   body > #header{position:fixed;}
//   #mscrollShadow {
//     transition: all 0.5s linear;
// }

//   #mscrollShadow.active {
//       box-shadow: 0 0 10px rgba(0,0,0,0.4);
//   }

.headerPadding {
  padding: 3px 5.6vw;
}

@media screen and (min-width: 800px) {
  .HomeheaderCls .navbar {
    position: fixed;
    width: 100%;
    z-index: 1;
    padding: 0px 0px 0px 0px;
    align-items: center;
    background-color: #e6eefa;
    /*box-shadow: -1px 0.5px 0.5px 0px #d7d4d4;*/
    /* box-shadow: -1px 9px 20px 0px #d7d4d4; */
    /* border: 1px solid red; */
  }

  .HomeheaderCls .main-nav {
    display: flex;
    // margin-right: 30px;
    width: 700px;
    padding: 0px;
    margin-left: auto;
    flex-direction: row;
    justify-content: flex-end;
  }

  .HomeheaderCls .main-nav li {
    margin: auto;
   
  }

  .HomeheaderCls .nav-links {
    margin-left: 30px;
    /* margin-right: 10px;  */
  }

  .navbr-navv-links {
    margin-left: 0px;
  }

  .HomeheaderCls .logo {
    margin-bottom: 20px;
  }

  .HomeheaderCls .navbar-toggle {
    display: none;
  }

  .HomeheaderCls .nav-links:hover {
    color: #1987fb;
    justify-content: center;
  }

  .navbr-navv-links {
    color: #1987fb;
    justify-content: center;
    margin-left: 0px;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .HomeheaderCls .navbar {
    padding: 10px 10px 0px 10px;
  }

  .HomeheaderCls .main-nav {
    padding: 0px;
    margin-left: 0px;
  }

  .main-navigation {
    padding: 0px;
    margin-left: 0px;
  }
  // .HomeheaderCls .liLink {
  //   margin-bottom: auto !important ;
  // }
}

@media (max-width: 800px) {
  .HomeheaderCls .navbar {
    display: block;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 1;
    padding: 20px 0px 20px 0px;
    align-items: center;
    box-shadow: 0px 10px 30px rgba(94, 125, 182, 0.1);
  }

  .HomeheaderCls .headWeb {
    display: none;
  }

  .HomeheaderCls .main-nav {
    height: 90vh;
    padding-top: 30px;
  }

  .main-navigation {
    display: none;
    height: 90vh;
    padding-top: 30px;
    text-align: center;
  }

  .HomeheaderCls .onsuimg {
    height: 30px;
    margin-top: 0px;
  }

  .HomeheaderCls .header {
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 10px;
  }

  .main-nav:hover {
    color: #1987fb;
  }

  .main-navigation:hover {
    // color: #1987fb;
  }

  .HomeheaderCls .logo {
    text-align: center;
  }

  .HomeheaderCls .liLink {
    padding: 15px 1rem 15px 1rem;
    font-size: 22px;
  }

  .navbr-lilinks-divv {
    padding: 15px 0px 15px 0px;
    font-size: 22px;
    // text-align: center;
  }

  .HomeheaderCls .headMob {
    display: block;
    font-size: 22px;
  }

  #navv {
    font-size: 16px;
  }

  #aboutus {
    color: #091c3f;
  }

  #navv:hover {
    color: #1987fb;
  }

  #aboutus:hover {
    color: #1987fb;
  }

  .HomeheaderCls .logo {
    margin-left: 16px;
  }

  #myaccount{
    margin-right: 4rem;
  }
}

//Tooltip menu
.tooltipMenu {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  min-height: 100px;
  right: -3.6rem;
  top: 3.5rem;
  // width: 100%;
  // border: 1px solid #0d77b6;
  // background: #FFFFFF;
  // box-shadow: 0px 10px 30px rgba(94, 125, 182, 0.1);
  border-radius: 18px;

}
.membership-fix{
  right: -5rem;
}

.vertical-devider::after{
  content: "";
  display: block !important;
  visibility: visible;
  background-color: #C6CBD4;
  height: 100%;
  width: 1px;
  margin-left:1rem;
}

.tooltipMenu ul {
  box-shadow: 0px 10px 30px rgba(94, 125, 182, 0.1);
}

.tooltip-wrapper:hover {
  position: relative;
  height: 100%;
  align-items: center;
  & .tooltipMenu {
    visibility: visible;
    cursor: pointer;
  }
}

.tooltipMenu::after {
  bottom: 100%;
  right: 20%;
  border-color: transparent transparent white transparent;
  content: " ";
  position: absolute;
  border-width: 10px;
  border-style: solid;
}

.liLink {
  display: flex;
}

.nav-links {
  display: flex;
  white-space: nowrap;
}
.nav-drop {
  padding-left: 10px;
}

// .fix{
//   right: -4.6rem !important;
// }

.home-list {
  padding: 1.5rem;
  list-style-type: none;
  background: #ffffff;
  border-radius: 18px;
  padding-right: 4rem;
  li{
    padding: 5px 0px;
    &:hover{
      color:#007CFF;
    }
  }
  & .homeListLi{
    color: #091c3f;
    white-space: nowrap;
  }
}
.home-list-member-mobile{

  border-radius:0 !important;

    background-color: #E9F2FF !important;
    & button{
      background-color: #fff !important;
    }
  
}

.home-list-member{
  padding: 17px 2rem;
  // padding-left: 0;
  font-family: Gilroy-regular;
  list-style-type: none;
  background: #ffffff;
  border-radius: 18px;

  .homeListLi {
    cursor: pointer;
    padding: 3px 1rem;
    &:hover{
      color: #007CFF;
    }
  }
  & li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 3px 0px;
    & p{
      margin: 0;
      white-space: nowrap;
      padding-right: 2rem;
    }
    & button{
      // margin-left: 1rem;
      color:#007CFF;
      // padding: 10px 2rem;
      font-weight: 600;
      width: 8rem;
      height: 2.5rem;
      background-color: rgba(0, 124, 255, 0.1);
      // opacity: 0.1;
      border-radius: 40px;
      border: none;
    }
  }
}

.accordion{
  font-family: Gilroy-regular;
  transition: all ease 0.3s;
  padding: 10px 1rem;
  font-size: 14px;
}

.d-block{
  background-color: #E9F2FF;

}

.accordion-wrapper{
  // padding:1rem !important
  #navv{
    font-size: 14px;
  }
  #navv1{
    font-size: 14px;
    color: #091c3f;
    line-height: 19px;
  }
}


.mobile-sidebar{
  position: absolute;
  bottom: 5rem;
  width: 100%;
  & img{
    
    height: 4rem !important;
  }
}