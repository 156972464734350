@font-face {
  font-family: 'Roboto';
  src: url('./font/Gilroy-Regular/font.woff') format('woff'),
       url('./font/Gilroy-Regular/font.woff2') format('woff2'); 
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} 

@font-face {
  font-family: 'Gilroy';
  src: url('./font/Gilroy-Bold/font.woff') format('woff'),
       url('./font/Gilroy-Bold/font.woff2') format('woff2'); 
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy-regular';
  src: url('./font/Gilroy-Regular/font.woff') format('woff'),
       url('./font/Gilroy-Regular/font.woff2') format('woff2'); 
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Gilroy' ,"Gilroy-regular",'sans-serif' !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'robotoregular';
}

