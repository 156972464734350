.partner-card-div{
    gap: 30px;
    font-family: 'Gilroy-regular';
    background: #ffffff;
    width: 270px;
    height: 250px;
    border-radius: 10px;
    padding: 0px 10px;
    margin: auto;

    .partner-card-logo{
        width: 80px;
        height: 120px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        img{
            width: 70px;
        }
    }
}