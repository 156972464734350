@mixin flex-base{
  display: flex;
  align-items: center;
}

.partner-page{
  .top-header{
    width: 100%;
    .logo1{
      @include flex-base;
      justify-content: space-around;
      img{
        height: 60px;
      }
    }
  }
  
  .banner{
    width: 95%;
    margin: auto;
    margin-top: 10%;
    .banner-text{
      .heading1{
        font-size: 1.875rem;
        font-weight: 500;
        line-height: 1.2;
      }

      .heading2{
        font-size: 2.6875rem;
        line-height: 1.1;
      }

      .heading3{
        font-size: 1.5625rem;
        font-weight: 100;
        line-height: 1.375;

        .emp-text{
          font-size: 1.8rem;
          color: black;
        }
      }

      .banner-cta{
        @include flex-base;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 20px;
        button{
          border: 0;
          border-radius: 10px;
          padding: 20px 30px;
          box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
        }
      }
    }

    .banner-image{
      margin-top: 50px;
      @include flex-base;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .section-heading{
    @include flex-base;
    justify-content: space-around;
    h2{
        font-weight: 500;
        line-height: 1.2;
    }
  }

  .onsurity-benefits{
    margin-top: 50px;
    .onsurity-benefits-content{
      @include flex-base;
      justify-content: space-around;
      flex-direction: column;
    }
  } 

  .why-onsurity{
    margin-top: 50px;
    .why-onsurity-content{
      @include flex-base;
      justify-content: space-around;
      flex-wrap: wrap;
      .parent-wrapper{
        width: 150px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }

  .testimonials-section{
    margin-top: 20px;
    margin-bottom: 20px;
    .section-heading{
      text-align: center;
    }

    .testimonials-content{
      margin-top: 50px;
      @include flex-base;
      flex-direction: column;
      justify-content: space-around;
      flex-wrap: wrap;

      .parent-wrapper{
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

// Top Banner CTA
.talk-to-us-cta{
  button{
    border: 0;
    padding: 10px 45px;
    border-radius: 40px;
    box-shadow: rgba(50, 50, 93, .25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  display: none;
}

// Media Queries
@media (min-width: 1024px){
  .talk-to-us-cta{
    display: block;
  }

  .partner-page{
    .top-header{
      @include flex-base;
      justify-content: space-between;
      width: 90%;
      margin: auto;
    }

    .banner{
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin-top: 5%;

      .banner-text{
        width: 40%;

        .heading1{
          font-size: 2rem;
        }
        .heading2{
          font-size: 2.9rem;
          margin-top: 20px;
        }
        .heading3{
          font-size: 1.8rem;
          margin-top: 20px;
        }

        .banner-cta{
          margin-top: 50px;
        }
      }

      .banner-image{
        width: 50%;
        margin-top: 0;
      }
    }

    .onsurity-benefits{
      width: 90%;
      margin: auto;
      margin-top: 70px;
      .onsurity-benefits-content{
        margin-top: 50px;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .why-onsurity{
      width: 90%;
      margin: auto;
      margin-top: 80px;
      .why-onsurity-content{
        .parent-wrapper{
          height: 150px;
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }
      }
    }

    .testimonials-section{
      margin-top: 80px;
      margin-bottom: 80px;
      .testimonials-content{
        flex-direction: row;
        justify-content: space-evenly;
      }
    }
  }
}