@mixin flex-base{
  display: flex;
  align-items: center;
}

.benefits-card{
  border: 1px #007dff75 solid;
  border-radius: 10px;
  background-color: #e9f1ff;
  width: 350px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  margin-top: 10px;
  margin-bottom: 10px;

  .benefits-card-wrapper{
    // Flex
    @include flex-base;
    flex-direction: column;
    justify-content: 'space-around';

    .top-header-wrapper{
      width: 90%;
      text-align: center;
      margin-top: 10px;
      .top-header{
        font-size: 1.5rem;
        margin-top: 7px;
        margin-bottom: 7px;
      }
    }

    .card-points{
      font-family: 'Gilroy-regular';
      width: 100%;
      height: 220px;
      margin-top: 10px;
    }
    
    .price-block{
      margin-top: 10px;
      margin-bottom: 10px;
      width: 90%;
      @include flex-base;
      justify-content: space-around;
      p{
        font-weight: bold;
        // font-size: 1.0625rem;
        font-size: 1.17rem;
        width: 70%;
        text-align: center;
      }
    }

    .card-btn-wrapper{
      margin-bottom: 15px;
      width: 90%;
      @include flex-base;
      justify-content: space-around;
      .card-btn{
        border: 0;
        width: 70%;
        border-radius: 50px;
        color: #FFFFFF;
        padding: 12px 20px !important;
        background-color: #9ac04f !important;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }

  ul{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  li{
    margin: auto;
    width: 80%;
    text-align: left;
    font-size: 0.85rem;
  }

  li:not(:first-child) {
    margin-top: 11px;
  } 
}