
/* switch toggle css */

.switch_toggle {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 22px;
    margin: 0px;
  }
  
  .switch_toggle .Check_Toggle { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider_togglr {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider_togglr:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 0px;
    /* bottom: 4px; */
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
  }
  
  .Check_Toggle:checked + .slider_togglr {
    background-color: #1987FB;
  }
  
  .Check_Toggle:checked + .slider_togglr:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  
  /* Rounded slider_togglrs */
  .slider_togglr.round_toggle {
    border-radius: 34px;
  }
  
  .slider_togglr.round_toggle:before {
    border-radius: 50%;
  }


  /* @media(max-width:600px){
    .Check_Toggle:checked + .slider_togglr:before {
      -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
    }
  } */