.snd_cmp_name_cls {
	width: 30%;
}

.fir_ept_cls {
	width: 15%;
}

.trd_info_div_cls {
	width: 200px;
	margin-left: 27%;
}

.ftMar0px {
	margin-left: 0px !important;
}

.wid15Per {
	width: 15%;
}

.wid35Per {
	width: 35%;
}

.ft_algn_cntr {
	/* margin-left: 150px; */
}

.cmp_nm_ft_cls {
	font-family: Gilroy;
	width: 300px;
	font-size: 24px;
	font-weight: 800;
	line-height: 1.2;
}

.info_nm_ft_cls {
	font-size: 22px;
	font-weight: 800;
	color: #091C3F;
	font-family: "Gilroy";
}

.ft_dtls_cnt_Cls {
	margin-top: 5px;
	color: rgba(9, 28, 63, 0.5);
	font-size: 15px;

}

.frth_lgl_div_cls {
	width: 135px;
}

.fifh_news_div_cls {
	width: 22%;
}

.ft_disp_flex {
	margin-top: 5%;
	display: flex;
}

.ft_btns_rgt {
	margin-right: 3%;
}

.ft_bgclr_bl {
	/* background-color: #091C3F; */
	margin-top: 5%;
	height: 61px;
	text-align: center;
	margin-left: 0px;
	/* transform: translatex(-100px); */
}

.ft_clr_blk {
	color: #848D9F;
	vertical-align: middle;
	line-height: 60px;
}

.rmvPad_ft {
	padding-left: 0px;
}

.hrdivv {
	width: '85%';
	margin: 'auto';
}

.footer-mg {
	margin-top: 50px;
}

.dispFlxContFooter {
	display: flex;
	
}

.mobile_footer_flwex{
	display: none;
}

@media (max-width : 500px) {

	.footer_div_hide{
		display: none !important;
	}
	.snd_cmp_name_cls {
		width: 100%;
	}

	.mobile_footer_flwex{
		display: block;
		display: flex;
		margin-top: 25px;
	}

	.ft_bgclr_bl {
		transform: none;
	}

	.dispFlxContFooter {
		flex-direction: column;

	}

	.trd_info_div_cls {
		width: 100%;
		margin: auto;
		margin-left: 0px;
		margin-top: 20px;
	}

	.frth_lgl_div_cls {
		display: block;
		width: 100%;
		padding-top: 30px;
	}

.cmp_nm_ft_cls {
	width: 100%;
}

	.ft_disp_flex {
		display: block;
	}

	.ft_algn_cntr {
		margin-left: auto;
		text-align: center;
	}

	.hrdiv {
		display: none;
	}
	.ft_dtls_cnt_Cls {
		/* width: fit-content; */
		margin: 10px 0px;
		color: #6B778C;
		/* margin: auto; */

	}

	.pvPolFtCls {}
}
