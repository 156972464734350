.about-us-top-div{
    margin: 0 auto;
    padding: 80px;
    text-align: center;
    background: rgba(89, 181, 230, 1) !important;

    & .p-div{
        font-family: 'Gilroy-regular';
    }
}
@media (max-width : 780px) {
    .about-us-top-div{
      
        padding: 80px 15px;
        text-align: center;
        background: rgba(89, 181, 230, 1) !important;
    }
  }