.get-start-with-onsurity-top-div{
    background-color: rgba(89, 181, 230, 1);
    padding: 80px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    & .ptag{
        font-size: 16px;
        line-height: 1.375;
        margin-top: 6px;
    }
    & .partner-card-top-div{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        margin: 0 auto;
        padding-top: 80px;
        // padding: 0px 100px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
    }
    & .buttom-div{
        margin-top: 4rem;
        padding: 18px 30px;
        border-radius: 40px;
        background-color: #9ac04f;
        color: #ffffff;
        cursor: pointer;
    }

   
}
.question-ans-top-div-lable{
  text-align: center;
    font-size: 28px;
    padding-bottom: 45px;
}
.question-ans-top-div{
  padding: 80px;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header {
  align-items: center;
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header >.ant-collapse-header-text {
  font-size: 20px;
  font-weight: 600;
}
.ant-collapse>.ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
  background: #ffffff;
}
.sliderWrapperResponse{
    .slick-slide{
      // display: none;
    }
    .slick-dots{
      display: block;
      bottom: -3rem;
    }
  }
  .slider-top-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 80px 88px;
    text-align: center;

  }
  .slider-image-top-div{
    padding: 0px 30px;
    
  }
//   .slick-initialized .slick-slide{
//     width: fit-content !important;

//   }
  .trusted-top-div-text{
    padding-bottom: 40px;
  }
@media (max-width : 780px) {
    .get-start-with-onsurity-top-div{

        & .partner-card-top-div{
            display: flex;
            flex-wrap: wrap;
            row-gap: 5px;
            column-gap: 10px;
        }

    }
    .question-ans-top-div{
      padding: 80px 15px;
    }
    .slider-top-div{
        padding: 80px 0px;
    
      }

    .sliderWrapperResponse {
             width: 63vw;
             margin: auto;
     }
  }

  .footer-wrapper{
    font-family: Gilroy-regular;
    background-color: #fff;
    padding: 4rem 5.6vw;
  }


  ///rupam 
  
  
