.loader-container{
    position: fixed;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    background: white;
    // width: 100%;
    // height: 100%;
    z-index: 99;
}


.lader-parent .loader-container{
    background-color: white;
    position: fixed;
    height: 100%;
    width: 100%;
}