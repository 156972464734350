
/*Common Sylings starts*/
$main-head-color: #091C3F;
$cnt-color: rgba(9, 28, 63, 0.6);
$font-family-roboto: "Roboto";
$font-family-gilroy: 'Gilroy';
/*Common Sylings ends*/

/*Larger Desktops starts*/
$main-head-font-weight-web-xl: 800;
$main-head-font-size-web-xl: 2rem;
$main-head-line-hght-max-web-xl: 124%;
$main-head-lrtSpace-max-web-xl: 0.005em;
$main-head-line-hght-web-xl: 51px;
$main-head-lrtSpace-web-xl: 0.006em;

$cnt-fnt-size-web-xl: 16px;
$cnt-fnt-wght-web-xl: normal;
$cnt-lin-hght-web-xl: 25px;
$cnt-lrtSpace-web-xl: -0.0045em;
$cnt-fnt-style-web-xl: normal;
/*Larger Desktops ends*/

/*Desktop starts*/
$main-head-font-weight-web: 800;
$main-head-font-size-web: 1.6rem;
$main-head-line-hght-max-web: 124%;
$main-head-lrtSpace-max-web: 0.005em;
$main-head-line-hght-web: 51px;
$main-head-lrtSpace-web: 0.006em;

$cnt-fnt-size-web: 16px;
$cnt-fnt-wght-web: normal;
$cnt-lin-hght-web: 25px;
$cnt-lrtSpace-web: -0.0045em;
$cnt-fnt-style-web: normal;
/*Desktop ends*/
 
/*Tablet starts*/
$main-head-font-weight-tab: bold;
$main-head-font-size-tab: 35px;
$main-head-line-hght-max-tab: 124%;
$main-head-lrtSpace-max-tab: 0.005em;
$main-head-line-hght-tab: 43px;
$main-head-lrtSpace-tab: 0.006em;

$cnt-fnt-size-tab: 16px;
$cnt-fnt-wght-tab: normal;
$cnt-fnt-style-tab:normal;
$cnt-lin-hght-tab: 30px;
$cnt-lrtSpace-tab: -0.0045em;
/*Tablet ends*/

/*Mobile starts*/
$main-head-font-weight-mob: 800;
$main-head-font-size-mob: 26px;
$main-head-line-hght-max-mob: 124%;
$main-head-lrtSpace-max-mob: 0.005em;
$main-head-lrtSpace-mob: 0.005em;

$cnt-fnt-size-mob: 14px;
$cnt-fnt-wght-mob: normal;
$cnt-fnt-style-mob: normal;
$cnt-lin-hght-mob: 20px;
$cnt-lrtSpace-mob: -0.0045em;
/*Mobile ends*/

/*button style starts*/
$btn-primary-bg-color: #1987FB; /*Primary color blue and white*/
$btn-primary-color: #FFFFFF;
$btn-shade-bg-color: #F5F9FF;
$btn-shade-color: #000000;
/*butto style ends*/


.global-font-cls{
  font-family: $font-family-gilroy;
  font-size: 20px !important;
  margin-bottom: 10px;
}
/* PURCHASE SECTION START */

  //LARGE DESKOP
  $purchase-section-width-web-xxl:60%;
  $purchase-section-height-web-xxl:650px;
  $purchase-section-left-web-xxl:20%;

  //DESKTOP SIZE
  $purchase-section-width-web-xl:60%;
  $purchase-section-height-web-xl:500px;
  $purchase-section-left-web-xl:20%;

  //LAPTOP STYLE
  $purchase-section-width-web:60%;
  $purchase-section-height-web:450px;
  $purchase-section-left-web:20%;

  // TAB STYLE
  $purchase-section-width-tab:90%;
  $purchase-section-height-tab:900px;
  $purchase-section-left-tab:'unset';

  //MOBILE STYLE
  $purchase-section-width-mob:90%;
  $purchase-section-height-mob:800px;
  $purchase-section-left-mob:'unset';

/* PURCHASE SECTION ENDS */

.btnPrimary{
  background-color: $btn-primary-bg-color;
  color: $btn-primary-color;
}
.btnSecondary{
  background-color: $btn-shade-bg-color;
  color: $btn-shade-color;
}

.mainHeading_max{
  font-weight: $main-head-font-weight-web;
  font-size: $main-head-font-size-web;
  color: $main-head-color;
  line-height: $main-head-line-hght-max-web;
  letter-spacing: $main-head-lrtSpace-max-web;
}
.mainHeading{
  font-weight: $main-head-font-weight-web;
  font-size: $main-head-font-size-web;
  color: $main-head-color;
  line-height: $main-head-line-hght-web;
  letter-spacing: $main-head-lrtSpace-web;
}

.contentTxt{
  font-size: $cnt-fnt-size-web;
  line-height: $cnt-lin-hght-web;
  color: $cnt-color;
  font-weight: normal;
}


.transition{
  transition: all .3s;
  // transition-timing-function: cubic-bezier(0.94, -0.22, 0, 1.04);
  -wenkit-transition: all .3s;
  -moz-transition: all .3s;
  -o-transition: all .3s;
  -ms-transition: all .3s;
}

.homeWrapperComponents{
  background-color: #E9F1FF;
  // width: 90%;
  padding: 0px 5.6vw;

  margin: auto;
}
.homeScrollOrgComponents{
  // margin-left: 5%;
  padding-left: 5.6vw;
  padding-right: 5.6vw;
}


@media screen and ( max-width: 800px){
  #slideFirstSecHmId{

    padding-bottom: 9rem;
  }
  
  .homeScrollOrgComponents{
    // margin-left: 5%;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

/*XX Large  1800px*/
@media screen and (min-width: 1600px){
  .purchaseSectionGlobal{
    // width: $purchase-section-width-web-xxl;
    // height: $purchase-section-height-web-xxl;
    // left:  $purchase-section-left-web-xxl;
    width: 900px;
    height: 500px;
    transform: scale(1.2);
  }
  .homeWrapperComponents{
    // width: 1500px;
    // padding: 0px 5rem;
    width: 100%;
    margin: auto;
  }
  .homeScrollOrgComponents{
    // width: 1500px;
    margin: auto;
  }
}

@media screen and (min-width: 1600px){
  .mainHeading_max{
    font-size: 39px;
    font-family: $font-family-gilroy;
  }
  .global-font-cls{
    font-size: 20px !important;
    margin-bottom: 10px;
  }
  .contentTxt{
    font-size: 18px;
  }
}
/*Large Desktop Screens starts*/
@media screen and (min-width: 1281px) and (max-width: 1599px){
  .mainHeading_max{
    font-weight: $main-head-font-weight-web-xl;
    font-size: $main-head-font-size-web-xl;
    color: $main-head-color;
    line-height: $main-head-line-hght-max-web-xl;
    letter-spacing: $main-head-lrtSpace-max-web-xl;
    font-family: $font-family-gilroy;
  }
  .contentTxt{
    font-size: $cnt-fnt-size-web-xl;
    line-height: $cnt-lin-hght-web-xl;
    color: $cnt-color;
    font-weight: $cnt-fnt-wght-web-xl;
    font-style: $cnt-fnt-style-web-xl;
    font-family: $font-family-roboto;
  }

  .purchaseSectionGlobal{
    width: $purchase-section-width-web-xl;
    height: $purchase-section-height-web-xl;
    left:  $purchase-section-left-web-xl;
  }
}
/*large Desktop screens ends*/

/*Normal Desktops starts*/
@media screen and (min-width: 1024px) and (max-width: 1280px){
  .mainHeading_max{
    font-weight: $main-head-font-weight-web;
    font-size: $main-head-font-size-web;
    color: $main-head-color;
    line-height: $main-head-line-hght-max-web;
    letter-spacing: $main-head-lrtSpace-max-web;
    font-family: $font-family-gilroy;
  }
  .contentTxt{
    font-size: $cnt-fnt-size-web;
    line-height: $cnt-lin-hght-web;
    color: $cnt-color;
    font-weight: $cnt-fnt-wght-web;
    font-style: $cnt-fnt-style-web;
    font-family: $font-family-roboto;
  }

  .purchaseSectionGlobal{
    width: $purchase-section-width-web;
    height: $purchase-section-height-web;
    left:  $purchase-section-left-web;
  }
}
/*Normal Desktops ends*/

/*Tab Screen resolution starts*/
@media screen and (min-width: 481px) and (max-width: 1023px){
  .mainHeading_max{
    font-weight: $main-head-font-weight-tab;
    font-size: $main-head-font-size-tab;
    color: $main-head-color;
    line-height: $main-head-line-hght-max-tab;
    letter-spacing: $main-head-lrtSpace-max-tab;
    font-family: $font-family-gilroy;
  }
  .contentTxt{
    font-size: $cnt-fnt-size-tab;
    line-height: $cnt-lin-hght-tab;
    color: $cnt-color;
    font-weight: $cnt-fnt-wght-tab;
    font-style: $cnt-fnt-style-tab;
    font-family: $font-family-roboto;
  }

  .purchaseSectionGlobal{
    width: $purchase-section-width-tab;
    height: $purchase-section-height-tab;
    left:  $purchase-section-left-tab;
  }
}
/*Tab screen resolution ends*/



/*Mobile Screen resolution starts*/
@media screen and (max-width: 480px) {
  .mainHeading_max{
    font-weight: $main-head-font-weight-mob;
    font-size: $main-head-font-size-mob;
    color: $main-head-color;
    line-height: $main-head-line-hght-max-mob;
    letter-spacing: $main-head-lrtSpace-max-mob;
    font-family: $font-family-gilroy;
    margin:auto;
    width: 240px;
    text-align: center;
  }
  .contentTxt{
    font-size: $cnt-fnt-size-mob;
    line-height: $cnt-lin-hght-mob;
    color: $cnt-color;
    font-weight: $cnt-fnt-wght-mob;
    font-style: $cnt-fnt-style-mob;
    font-family: $font-family-roboto;
  }

  .purchaseSectionGlobal{
    width: $purchase-section-width-mob;
    height: $purchase-section-height-mob;
    left:  $purchase-section-left-mob;
  }
  .global-font-cls{
      text-align: left;
      margin: unset;
      margin-bottom: 10px;
  }

}
/*Mobile screen resolution ends*/


.g-img-box-shadow{
  box-shadow:  0px 10px 30px rgba(94, 125, 182, 0.1);
  border-radius: 18px;
}


@media (max-width : 361px) {
  #slideFirstSecHmId{
    padding-bottom: 12rem;
  }
}

/*Mobile Screen resolution starts*/
@media screen and (max-width: 360px) {
  .mainHeading_max{
    font-size: 24px;
  }

}