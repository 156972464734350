.terms-txt-div{
    font-family: Gilroy;
    color: #091C3F;
    font-size: 32px;
    font-weight: 800;
    text-align: center;
    margin-top: 30px;
}
.definitions-div{
    font-family: Gilroy;
    color: #091C3F;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}
.wrp-terms-cls{
    width: 60%;
    margin: auto;
    margin-top: 40px;
}
.desc-cls{
    font-family: Roboto;
    color: rgba(9, 28, 63, 0.6);
    font-size: 14px;
    line-height: 1.75;
}
.hr-middl-div{
    width: 105%;
    margin: auto;
    height: 1px;
    background-color: rgba(9, 28, 63, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
}
@media(max-width: 720px){
    .wrp-terms-cls{
        width: 90%;
        margin: auto;
        margin-top: 40px;
    } 
}