.privacy-polcy{
   width: 100%;
   height: 80vh;
   margin-top: 30px;
   border: none;
}
ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }
  
  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }
  
  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;    
  }
  
  li ol > li {
    margin: 0;
  }
  
  li ol > li:before {
    content: counters(item, ".") " ";
  }
  .unordered-cls {
    list-style: none;
  }
  
  .unordered-cls  ::before {
    content: "•";
    font-size: 20px;
    color: #1987FB;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
  .unordered-list{
     margin-left: 70px;
  }
  .third-policy-cls{
      width: 80%;
      margin: auto;
  }
 .lists-digit{
     color: rgba(9, 28, 63, 0.6);
     font-size: 14px;
 }
 .span-weight{
     font-weight: bold;
     color: #091C3F;
 }
 .inner-lists{
     color: #091C3F;
     font-weight: bold;
    font-size: 14px;
 }
 .desc-clss{
    color: rgba(9, 28, 63, 0.6);
    font-size: 14px;
    line-height: 1.75;
    font-weight: 600;
 }

 @media(max-width: 720px){
    .unordered-list{
        margin-left: 0px;
     }
     .third-policy-cls{
         width: 90%;
         margin: auto;
     }
     ul{
         text-align: left;
     }
 }